/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import appActions from 'redux/actions/appActions';
import { Link, Redirect } from 'react-router-dom';

import Stepper from 'components/Stepper/Stepper';
import SquareIncrement from 'components/SquareIncrement/SquareIncrement';
import Radio from 'components/Checkbox/Checkbox';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { gestionError, getValueStock, handleCodificationsInfobulles } from 'utils';

import Button from '@material-ui/core/Button';
import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Alert } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import img from 'assets/img/bi-gout.png';

export default function Form(props) {
  const useStyles = makeStyles({
    card: {
      height: '100%',
      width: '100%',
      overflow: 'hidden'
    },
    logo: {
      marginTop: 58
    },
    textField: {
      marginLeft: props.justify === 'flex-start' && 16,
      width: props.justify === 'flex-start' ? '90%' : 134,
      height: 44,
      borderRadius: 4,
      border: 'solid 0px #d6d6d6'
    },
    paragraphe: {
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#323232',
      marginLeft: props.justify === 'flex-start' && 16
    },
    alert: {
      maxWidth: props.justify === 'flex-start' ? '82%' : 693,
      minHeight: 89,
      borderRadius: 8,
      backgroundColor: '#FFF1EC',
      marginLeft: props.justify === 'flex-start' && 16
    },
    infosAlert: {
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.36,
      letterSpacing: 'normal',
      color: '#D7805B',
      marginLeft: props.justify === 'flex-start' && 16,
      width: props.justify === 'flex-start' && 253
    },
    select: {
      width: props.justify === 'flex-start' ? '90%' : 360,
      height: 44,
      backgroundColor: '#ffffff',
      marginLeft: props.justify === 'flex-start' && 16
    },
    datePicker: {
      width: 200,
      height: 44,
      borderRadius: 4,
      border: 'solid 0px #d6d6d6',
      marginLeft: props.justify === 'flex-start' && 16
    },
    radio: {
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#323232',
      marginLeft: props.justify === 'flex-start' && 16
    },
    containerText: {
      display: props.justify !== 'flex-start' && 'flex'
    },
    finalSpan: {
      fontFamily: 'Work Sans',
      fontSize: 32,
      fontWeight: 900,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#4a4a49'
    },
    final: {
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.57,
      letterSpacing: 'normal',
      color: '#4a4a49',
      textAlign: props.justify === 'flex-start' && 'center'
    },
    checkbox: {
      height: 16,
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#4a4a49'
    },
    error: {
      color: 'red',
      fontFamily: 'Work Sans'
    },
    textArea: {
      marginLeft: props.justify === 'flex-start' && 16,
      width: props.justify === 'flex-start' ? '90%' : 500,
      height: 44,
      borderRadius: 4,
      border: 'solid 1px black',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 'inherit',
      padding: '10px'
    }
  });

  const theme = createMuiTheme({
    palette: {
      primary: { main: '#E85313' }
    }
  });

  const tooltip = createMuiTheme({
    palette: {
      primary: { main: '#E85313' }
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          maxWidth: 369,
          borderRadius: 8,
          boxShadow: '0 0 14px 0 rgba(56, 20, 5, 0.14), 0 0 4px 0 rgba(60, 22, 6, 0.07)',
          backgroundColor: '#ffffff',
          fontSize: 14,
          fontFamily: 'Work Sans',
          color: '#4a4a49'
        }
      }
    }
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  const [reseau, setReseau] = useState(null);
  const [valueStock, setValueStock] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [turnover, setTurnover] = useState(null);
  const [local, setLocal] = useState(null);
  const [error, setError] = useState(false);
  const [name, setName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [mail, setMail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [step, setStep] = useState(null);
  const [actuality, setActuality] = useState(false);
  const [checkboxData, setCheckboxData] = useState(true);
  const [checkEmail, setCheckEmail] = useState(true);
  const [lastStep, setLastStep] = useState(false);
  const [departement, setDepartement] = useState(null);
  const [estAccompagne, setEstAccompagne] = useState(null);
  const [checkPhone, setCheckPhone] = useState(true);
  const [textArea, setTextArea] = useState(null);

  const valueStockRedux = useSelector(store => store.appReducer.valueStock);
  const formReducer = useSelector(store => store.formReducer);
  const codifications = useSelector(store => store.appReducer.codifications);
  const codificationsTitre = useSelector(store => store.appReducer.codificationsTitre);
  const referentiel = useSelector(store => store.appReducer.referentielReseau);
  const startDate = new Date();

  useEffect(() => {
    console.log(formReducer)
  }, [formReducer]);

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleChange = (event, type) => {
    if (type === 'reseau') {
      setReseau(event.target.value);
    }
    if (type === 'valueStock') {
      setValueStock(event.target.value);
    }
    if (type === 'textArea') {
      setTextArea(event.target.value);
    }
  };

  const validateEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(formReducer.mail && formReducer.mail.data).toLowerCase());
  };

  const validatePhone = () => {
    const regex = /^((\+)33|0)[1-9](\d{2}){4}$/g;
    return regex.test(String(formReducer.phone && formReducer.phone.data));
  };

  const onSubmit = () => {
    if (props.step === 3) {
      dispatch(
        appActions.post_product_tarification({
          activitePrincipaleNaf:
            formReducer && formReducer.activite && formReducer.activite.data && formReducer.activite.data.codeNAF,
          precisionActivite: formReducer && formReducer.textArea && formReducer.textArea.data,
          chiffreAffairesEstime: formReducer && formReducer.turnover && formReducer.turnover.data,
          codeTrancheMontantAAssurer: formReducer && formReducer.valueStock && formReducer.valueStock.data,
          nbAdultes: formReducer && formReducer.adult && formReducer.adult.data,
          nbEnfants: formReducer && formReducer.children && formReducer.children.data,
          possedeLocal: !(formReducer && formReducer.local && formReducer.local.data === 'Non'),
          prospect: {
            departement: formReducer && formReducer.departement && formReducer.departement.data,
            entrepriseDateCreation: formReducer && formReducer.formatIso && formReducer.formatIso.data,
            entrepriseEnCreation: !(formReducer && formReducer.beingCreated && formReducer.beingCreated.data === 'Non'),
            estAffilieAGIFIEHP: !(formReducer && formReducer.agefiph && formReducer.agefiph.data === 'Non'),
            estAccompagne: !(formReducer && formReducer.reseauBoolean && formReducer.reseauBoolean.data === 'Non'),
            numeroReseau:
              formReducer &&
              formReducer.numeroReseau &&
              formReducer.numeroReseau.data &&
              formReducer.numeroReseau.data.numero
          },
          tailleLocal: formReducer && formReducer.localm2 && formReducer.localm2.data,
          travailleChezSoi: !(
            formReducer &&
            formReducer.travailleChezSoi &&
            formReducer.travailleChezSoi.data === 'Non'
          )
        })
      );
    }

    if (props.step === 4) {
      if (gestionError(formReducer, props.step, selectedDate) && validateEmail() && validatePhone()) {
        let calcul = 0;

        formReducer &&
          formReducer.tarif &&
          formReducer.tarif.data.forEach(tarif => {
            calcul += tarif.montantMensuel;
          });

        dispatch(
          appActions.post_prospect({
            activitePrincipaleNaf:
              formReducer && formReducer.activite && formReducer.activite.data && formReducer.activite.data.codeNAF,
            precisionActivite: formReducer && formReducer.textArea && formReducer.textArea.data,
            chiffreAffairesEstime: formReducer && formReducer.turnover && formReducer.turnover.data,
            codeTrancheMontantAAssurer: formReducer && formReducer.valueStock && formReducer.valueStock.data,
            libelleCodeTrancheMontantAAssurer: getValueStock(
              formReducer && formReducer.valueStock && formReducer.valueStock.data
            ),
            nbAdultes: formReducer && formReducer.adult && formReducer.adult.data,
            nbEnfants: formReducer && formReducer.children && formReducer.children.data,
            possedeLocal: !(formReducer && formReducer.local && formReducer.local.data === 'Non'),
            produitsSelectionnes: formReducer && formReducer.tarif && formReducer.tarif.data,
            prospect: {
              civiliteCode: formReducer && formReducer.gender && formReducer.gender.data.toUpperCase(),
              codePostal: formReducer && formReducer.cp && formReducer.cp.data && formReducer.cp.data.value,
              email: formReducer.mail && formReducer.mail.data,
              entrepriseDateCreation: formReducer && formReducer.formatIso && formReducer.formatIso.data,
              entrepriseEnCreation: !(
                formReducer &&
                formReducer.beingCreated &&
                formReducer.beingCreated.data === 'Non'
              ),
              estAffilieAGIFIEHP: !(formReducer && formReducer.agefiph && formReducer.agefiph.data === 'Non'),
              etatSynchroCode: 'NOUVEAU',
              montantTotalSouscrit: calcul,
              nom: formReducer.name && formReducer.name.data,
              nomComplet: formReducer.name && formReducer.name.data,
              numero: '',
              numeroReseau:
                formReducer &&
                formReducer.numeroReseau &&
                formReducer.numeroReseau.data &&
                formReducer.numeroReseau.data.numero,
              optinActualites: actuality,
              optinDatas: checkboxData,
              prenom: formReducer.firstName && formReducer.firstName.data,
              telephone: formReducer.phone && formReducer.phone.data,
              ville: formReducer && formReducer.city && formReducer.city.data,
              estAccompagne: !(formReducer && formReducer.reseauBoolean && formReducer.reseauBoolean.data === 'Non')
            },
            tailleLocal: formReducer && formReducer.localm2 && formReducer.localm2.data,
            travailleChezSoi: !(
              formReducer &&
              formReducer.travailleChezSoi &&
              formReducer.travailleChezSoi.data === 'Non'
            )
          })
        );
        setLastStep(true);
      }
    }
    if (props.step === 1) {
      if (selectedDate !== null) {
        const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        };
        const dateFormat = selectedDate.toLocaleDateString('fr-US', options);
        dispatch(appActions.data_form(dateFormat, 'created'));
        dispatch(appActions.data_form(selectedDate.toISOString(), 'formatIso'));
      } else if (formReducer && formReducer.created && formReducer.created.data === null) {
        const options = {};
        const dateDefault = new Date().toLocaleDateString('fr-US', options);
        dispatch(appActions.data_form(dateDefault, 'created'));
        dispatch(appActions.data_form(new Date().toISOString(), 'formatIso'));
      }
    }
    if (!gestionError(formReducer, props.step, selectedDate)) {
      setError(true);
    } else {
      setStep(props.step);
    }
    if (validateEmail()) {
      setCheckEmail(true);
    } else {
      setCheckEmail(false);
    }
    if (validatePhone()) {
      setCheckPhone(true);
    } else {
      setCheckPhone(false);
    }
  };

  useEffect(() => {
    if (props.step === 1 && departement !== null && departement !== '') {
      dispatch(appActions.data_form(departement, 'departement'));
    }
  }, [props.step, departement, dispatch]);

  useEffect(() => {
    if (props.step === 1 && estAccompagne !== null && estAccompagne !== '') {
      dispatch(appActions.data_form(estAccompagne, 'estAccompagne'));
    }
  }, [props.step, estAccompagne, dispatch]);

  useEffect(() => {
    if (departement && departement !== null && departement.length === 2 && props.step === 1) {
      dispatch(
        appActions.post_referentiel_reseau({
          departement: departement && departement
        })
      );
    }
  }, [departement, props.step, dispatch]);

  useEffect(() => {
    if (props.step === 2 && valueStock !== '') {
      dispatch(appActions.data_form(valueStock, 'valueStock'));
    }
  }, [props.step, valueStock, dispatch]);

  useEffect(() => {
    if (props.step === 2 && textArea !== null) {
      dispatch(appActions.data_form(textArea, 'textArea'));
    }
  }, [props.step, textArea, dispatch]);

  useEffect(() => {
    if (props.step === 2 && local !== null) {
      dispatch(appActions.data_form(local, 'localm2'));
    }
  }, [props.step, local, dispatch]);

  useEffect(() => {
    if (props.step === 2 && turnover !== null) {
      dispatch(appActions.data_form(turnover, 'turnover'));
    }
  }, [props.step, turnover, dispatch]);

  useEffect(() => {
    if (props.step === 2) {
      dispatch(appActions.get_value_stock());
    }
  }, [props.step, dispatch]);

  useEffect(() => {
    if (props.step === 4 && name !== '' && name !== null) {
      dispatch(appActions.data_form(name, 'name'));
    }
  }, [props.step, name, dispatch]);

  useEffect(() => {
    if (props.step === 4 && firstName !== '' && firstName !== null) {
      dispatch(appActions.data_form(firstName, 'firstName'));
    }
  }, [props.step, firstName, dispatch]);

  useEffect(() => {
    if (props.step === 4 && mail !== '' && mail !== null) {
      dispatch(appActions.data_form(mail, 'mail'));
    }
  }, [props.step, mail, dispatch]);

  useEffect(() => {
    if (props.step === 4 && phone !== '' && phone !== null) {
      dispatch(appActions.data_form(phone, 'phone'));
    }
  }, [props.step, phone, dispatch]);

  useEffect(() => {
    if (props.step === 4 && checkboxData !== null) {
      dispatch(appActions.data_form(checkboxData, 'checkboxData'));
    }
  }, [props.step, checkboxData, dispatch]);

  useEffect(() => {
    if (props.step === 4 && actuality !== null) {
      dispatch(appActions.data_form(actuality, 'actuality'));
    }
  }, [props.step, actuality, dispatch]);

  if (!formReducer.jwt) {
    return <Redirect to="/step-1" />;
  }
  if (formReducer.jwt && formReducer.jwt.data === null) {
    window.location.assign('https://www.entrepreneursdelacite.org');
  }

  if (props.step === 1 && gestionError(formReducer, props.step, selectedDate) && step === props.step) {
    return <Redirect to="/step-2" />;
  }

  if (props.step === 2 && gestionError(formReducer, props.step, selectedDate) && step === props.step) {
    return <Redirect to="/step-3" />;
  }

  if (lastStep) {
    return <Redirect to="/send-mail" />;
  }

  return (
    <div className={classes.card}>
      <Hidden only="xs">
        <Grid container direction="row" justify="center" spacing={1} className={classes.logo}>
          <Grid item xs={12} sm={10} md={8}>
            <img src={img} alt="logo" />
          </Grid>
        </Grid>
      </Hidden>

      <Grid container direction="row" justify="center" spacing={1}>
        <Grid item xs={12} sm={10} md={8}>
          {props.step !== 4 && <Stepper step={props.step} />}
        </Grid>
      </Grid>

      {/* Etape 1 */}
      {props.step === 1 && (
        <div>
          <Grid container direction="row" justify="center" spacing={2}>
            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('DEPARTEMENT_ACTIVITE', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('DEPARTEMENT_ACTIVITE', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('DEPARTEMENT_ACTIVITE', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('DEPARTEMENT_ACTIVITE', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <p className={classes.paragraphe}>Département de votre activité</p>
                    <TextField
                      onChange={e => setDepartement(e.target.value)}
                      id="departement"
                      variant="outlined"
                      className={classes.textField}
                      defaultValue={
                        formReducer &&
                        formReducer.departement &&
                        formReducer.departement.data &&
                        formReducer.departement.data
                      }
                      inputProps={{ maxLength: 2, autoComplete: 'off' }}
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('SUIVI_RESEAU', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('SUIVI_RESEAU', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('SUIVI_RESEAU', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('SUIVI_RESEAU', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8} className={classes.radio}>
                    {props.justify === 'center' ? (
                      <p>Avez vous été suivi par un réseau partenaire lors de la création ?</p>
                    ) : (
                      <p>
                        Avez vous été suivi par un réseau <br />
                        partenaire lors de la création ?
                      </p>
                    )}
                    <Radio
                      firstLabel="Oui"
                      secondaryLabel="Non"
                      type="reseauBoolean"
                      defaultValue={formReducer && formReducer.reseauBoolean && formReducer.reseauBoolean.data}
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            {formReducer && formReducer.reseauBoolean && formReducer.reseauBoolean.data === 'Non' && (
              <Grid item xs={12} sm={10} md={8}>
                <Alert icon={false} className={classes.alert}>
                  <p className={classes.infosAlert}>
                    {' '}
                    Nous vous rappelons que vous devez être accompagnés par un réseau d&apos;aide à la création
                    d&apos;entreprise pour être éligible à la micro assurance
                  </p>
                </Alert>
              </Grid>
            )}

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('CHOIX_RESEAU', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('CHOIX_RESEAU', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('CHOIX_RESEAU', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('CHOIX_RESEAU', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <p className={classes.paragraphe}>Choix du réseau</p>
                    <Autocomplete
                      id="reseau"
                      justify={props.justify}
                      type="reseau"
                      step={1}
                      styleClasse="select"
                      defaultValue={
                        formReducer &&
                        formReducer.departement &&
                        formReducer.departement.data &&
                        formReducer.departement.data.length === 2
                          ? formReducer && formReducer.reseau && formReducer.reseau.data
                          : ''
                      }
                      reseau={true}
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            {formReducer &&
              formReducer.reseau &&
              formReducer.reseau.data !== '' &&
              formReducer.numeroReseau &&
              formReducer.numeroReseau.data &&
              formReducer.numeroReseau.data.informations && (
                <Grid item xs={12} sm={10} md={8}>
                  <Alert icon={false} className={classes.alert}>
                    <p
                      className={classes.infosAlert}
                      dangerouslySetInnerHTML={{
                        __html:
                          formReducer &&
                          formReducer.numeroReseau &&
                          formReducer.numeroReseau.data &&
                          formReducer.numeroReseau.data.informations
                      }}
                    ></p>
                  </Alert>
                </Grid>
              )}

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('EST_AGEFIPH', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('EST_AGEFIPH', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('EST_AGEFIPH', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('EST_AGEFIPH', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8} className={classes.radio}>
                    <p>Etes-vous reconnu travailleur handicapé et suivi par l'AGEFIPH ?</p>
                    <Radio
                      firstLabel="Oui"
                      secondaryLabel="Non"
                      type="agefiph"
                      defaultValue={formReducer && formReducer.agefiph && formReducer.agefiph.data}
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('DATE_CREATION_ENTREPRISE', codifications, codificationsTitre) ===
                    false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('DATE_CREATION_ENTREPRISE', codifications, codificationsTitre) ===
                    false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('DATE_CREATION_ENTREPRISE', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('DATE_CREATION_ENTREPRISE', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <p className={classes.paragraphe}>Date de création de l&apos;entreprise</p>
                    <MuiThemeProvider theme={theme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.datePicker}
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          value={selectedDate} // when value is null init with empty string, when value is "" init with invalid Date, when value is undefined init with current date
                          InputAdornmentProps={{ position: 'start' }}
                          onChange={date => handleDateChange(date)}
                          style={{ color: '#E85313' }}
                        />
                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('SOCIETE_EN_CREATION', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('SOCIETE_EN_CREATION', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('SOCIETE_EN_CREATION', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('SOCIETE_EN_CREATION', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8} className={classes.radio}>
                    <p>Société en cours de création</p>
                    <Radio
                      firstLabel="Oui"
                      secondaryLabel="Non"
                      type="beingCreated"
                      defaultValue={formReducer && formReducer.beingCreated && formReducer.beingCreated.data}
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            {error && (
              <Grid item xs={12} sm={10} md={8}>
                <p className={classes.error}>Veuillez remplir tout les champs.</p>
              </Grid>
            )}

            <Grid
              container
              item
              xs={12}
              sm={10}
              md={8}
              justify={props.justify !== 'flex-start' ? 'flex-start' : 'center'}
            >
              <Button
                onClick={onSubmit}
                variant="contained"
                className={props.buttonNextMobile ? props.buttonNextMobile : props.buttonNextDesktop}
              >
                Suivant
              </Button>
            </Grid>
          </Grid>
        </div>
      )}

      {/* Etape 2 */}
      {props.step === 2 && (
        <div>
          <Grid container direction="row" justify={props.justify} spacing={2}>
            <Grid item xs={12} sm={10} md={8}>
              <p className={classes.paragraphe}>Sélectionnez votre activité principale</p>
              <Autocomplete
                id="activite"
                justify={props.justify}
                type="activite"
                step={2}
                styleClasse="select"
                defaultValue={formReducer && formReducer.activite && formReducer.activite.data}
              />
            </Grid>

            {formReducer && formReducer.activite && formReducer.activite.data && formReducer.activite.data.codeNAF === 'A0898' &&
              <MuiThemeProvider theme={tooltip}>
                {
                  <Tooltip
                    disableHoverListener={
                      handleCodificationsInfobulles('ACTIVITE_SECONDAIRE', codifications, codificationsTitre) === false
                    }
                    disableFocusListener={
                      handleCodificationsInfobulles('ACTIVITE_SECONDAIRE', codifications, codificationsTitre) === false
                    }
                    interactive
                    placement="left"
                    title={
                      handleCodificationsInfobulles('ACTIVITE_SECONDAIRE', codifications, codificationsTitre)
                        ? handleCodificationsInfobulles('ACTIVITE_SECONDAIRE', codifications, codificationsTitre)
                        : ''
                    }
                  >
                    <Grid item xs={12} sm={10} md={8}>
                      <p className={classes.paragraphe}>
                        Merci de préciser la nature exacte de votre activité (merci d'être le plus précis possible)
                      </p>
                      <TextareaAutosize
                        onChange={e => handleChange(e, 'textArea')}
                        className={classes.textArea}
                        rowsMin={3}
                        value={formReducer && formReducer.textArea && formReducer.textArea.data}
                      />
                    </Grid>
                  </Tooltip>
                }
              </MuiThemeProvider>
            }

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('PRESENCE_LOCAL', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('PRESENCE_LOCAL', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('PRESENCE_LOCAL', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('PRESENCE_LOCAL', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8} className={classes.radio}>
                    <p>
                      <strong>Avez vous un local ?</strong> (ou un camion aménagé)
                    </p>
                    <Radio
                      firstLabel="Oui"
                      secondaryLabel="Non"
                      type="local"
                      defaultValue={formReducer && formReducer.local && formReducer.local.data}
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            {formReducer && formReducer.local && formReducer.local.data === 'Non' && (
              <MuiThemeProvider theme={tooltip}>
                {
                  <Tooltip
                    disableHoverListener={
                      handleCodificationsInfobulles('TRAVAILLE_DEPUIS_CHEZ_SOI', codifications, codificationsTitre) ===
                      false
                    }
                    disableFocusListener={
                      handleCodificationsInfobulles('TRAVAILLE_DEPUIS_CHEZ_SOI', codifications, codificationsTitre) ===
                      false
                    }
                    interactive
                    placement="left"
                    title={
                      handleCodificationsInfobulles('TRAVAILLE_DEPUIS_CHEZ_SOI', codifications, codificationsTitre)
                        ? handleCodificationsInfobulles('TRAVAILLE_DEPUIS_CHEZ_SOI', codifications, codificationsTitre)
                        : ''
                    }
                  >
                    <Grid item xs={12} sm={10} md={8} className={classes.radio}>
                      <p>
                        <strong>Vous travaillez de chez vous</strong> : faites vous de la fabrication depuis votre
                        domicile, ou recevez vous des clients à votre domicile ?
                      </p>
                      <Radio
                        firstLabel="Oui"
                        secondaryLabel="Non"
                        type="travailleChezSoi"
                        defaultValue={formReducer && formReducer.travailleChezSoi && formReducer.travailleChezSoi.data}
                      />
                    </Grid>
                  </Tooltip>
                }
              </MuiThemeProvider>
            )}
            {formReducer && formReducer.local && formReducer.local.data === 'Oui' && (
              <MuiThemeProvider theme={tooltip}>
                {
                  <Tooltip
                    disableHoverListener={
                      handleCodificationsInfobulles('TAILLE_LOCAL', codifications, codificationsTitre) === false
                    }
                    disableFocusListener={
                      handleCodificationsInfobulles('TAILLE_LOCAL', codifications, codificationsTitre) === false
                    }
                    interactive
                    placement="left"
                    title={
                      handleCodificationsInfobulles('TAILLE_LOCAL', codifications, codificationsTitre)
                        ? handleCodificationsInfobulles('TAILLE_LOCAL', codifications, codificationsTitre)
                        : ''
                    }
                  >
                    <Grid item xs={12} sm={10} md={8}>
                      <p className={classes.paragraphe}>Taille du local (m2)</p>
                      <TextField
                        id="m2"
                        onChange={e => setLocal(e.target.value)}
                        variant="outlined"
                        type="number"
                        className={classes.textField}
                        defaultValue={
                          formReducer && formReducer.localm2 && formReducer.localm2.data && formReducer.localm2.data
                        }
                      />
                    </Grid>
                  </Tooltip>
                }
              </MuiThemeProvider>
            )}

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('MONTANT_STOCK', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('MONTANT_STOCK', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('MONTANT_STOCK', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('MONTANT_STOCK', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <p className={classes.paragraphe}>Montant stock & materiel à assurer</p>
                    <FormControl variant="outlined" className={classes.select}>
                      <Select
                        labelId="value-stock-label"
                        id="value-stock"
                        onChange={e => handleChange(e, 'valueStock')}
                        value={valueStock}
                        displayEmpty
                      >
                        {formReducer && formReducer.valueStock && formReducer.valueStock.data && (
                          <MenuItem value="">
                            <em>
                              {formReducer &&
                                formReducer.valueStock &&
                                formReducer.valueStock.data &&
                                getValueStock(formReducer.valueStock.data)}
                            </em>
                          </MenuItem>
                        )}
                        {valueStockRedux &&
                        valueStockRedux.data !== null &&
                        valueStockRedux.data.body &&
                        Array.isArray(valueStockRedux.data.body) &&
                        valueStockRedux.data.body.length !== 0 ? (
                          valueStockRedux.data.body.map(res => (
                            <MenuItem value={res.codeInfoString} key={res.code}>
                              <em>{res.libelle}</em>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>
                            <em>Pas de données</em>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('CHIFFRE_AFFAIRE', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('CHIFFRE_AFFAIRE', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('CHIFFRE_AFFAIRE', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('CHIFFRE_AFFAIRE', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <p className={classes.paragraphe}>Chiffre d&apos;Affaires estimé (annuel en €)</p>
                    <TextField
                      id="turnover"
                      onChange={e => setTurnover(e.target.value)}
                      variant="outlined"
                      type="number"
                      className={classes.textField}
                      defaultValue={
                        formReducer && formReducer.turnover && formReducer.turnover.data && formReducer.turnover.data
                      }
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            {error && !gestionError(formReducer, 2) && (
              <Grid item xs={12} sm={10} md={8}>
                <p className={classes.error}>Veuillez remplir tout les champs.</p>
              </Grid>
            )}

            <Grid
              container
              item
              xs={12}
              sm={10}
              md={8}
              justify={props.justify !== 'flex-start' ? 'flex-start' : 'center'}
            >
              <Button
                component={Link}
                to="/step-1"
                variant="contained"
                className={props.buttonPrevMobile ? props.buttonPrevMobile : props.buttonPrevDesktop}
              >
                Précédent
              </Button>
              <Button
                onClick={onSubmit}
                variant="contained"
                className={props.buttonNextMobile ? props.buttonNextMobile : props.buttonNextDesktop}
              >
                Suivant
              </Button>
            </Grid>
          </Grid>
        </div>
      )}

      {/* Etape 3  */}
      {props.step === 3 && (
        <div>
          <Grid container direction="row" justify={props.justify} spacing={2}>
            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('NB_ADULTES', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('NB_ADULTES', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('NB_ADULTES', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('NB_ADULTES', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <SquareIncrement
                      type="adult"
                      justify={props.justify}
                      defaultValue={formReducer && formReducer.adult && formReducer.adult.data}
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('NB_ENFANTS', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('NB_ENFANTS', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('NB_ENFANTS', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('NB_ENFANTS', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <SquareIncrement
                      type="children"
                      justify={props.justify}
                      defaultValue={formReducer && formReducer.children && formReducer.children.data}
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <Grid
              container
              item
              xs={12}
              sm={10}
              md={8}
              justify={props.justify !== 'flex-start' ? 'flex-start' : 'center'}
            >
              <Button
                component={Link}
                to="/step-2"
                variant="contained"
                className={props.buttonPrevMobile ? props.buttonPrevMobile : props.buttonPrevDesktop}
              >
                Précédent
              </Button>
              <Button
                onClick={onSubmit}
                component={Link}
                to="/tarification"
                variant="contained"
                className={props.buttonNextMobile ? props.buttonNextMobile : props.buttonNextDesktop}
              >
                Valider et choisir ma formule
              </Button>
            </Grid>
          </Grid>
        </div>
      )}

      {/* Etape 4  */}
      {props.step === 4 && (
        <div>
          <Grid container direction="row" justify={props.justify} spacing={3}>
            <Grid item xs={12} sm={10} md={8}>
              <p className={classes.final}>
                <span className={classes.finalSpan}>Recevoir votre devis personnalisé</span>
              </p>
            </Grid>

            <Grid item xs={12} sm={10} md={8}>
              <p className={classes.final}>
                Merci de remplir le formulaire ci-dessous pour recevoir votre devis personnalisé par email
              </p>
            </Grid>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('CIVILITE', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('CIVILITE', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('CIVILITE', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('CIVILITE', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8} className={classes.radio}>
                    <Radio
                      firstLabel="Madame"
                      secondaryLabel="Monsieur"
                      type="gender"
                      defaultValue={formReducer && formReducer.gender && formReducer.gender.data}
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('NOM', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('NOM', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('NOM', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('NOM', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <p className={classes.paragraphe}>Nom</p>
                    <TextField
                      style={{ width: props.justify === 'center' ? 360 : '90%' }}
                      id="name"
                      variant="outlined"
                      className={classes.textField}
                      onChange={e => setName(e.target.value)}
                      defaultValue={formReducer && formReducer.name && formReducer.name.data && formReducer.name.data}
                      autoComplete="new-password"
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('PRENOM', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('PRENOM', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('PRENOM', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('PRENOM', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <p className={classes.paragraphe}>Prénom</p>
                    <TextField
                      style={{ width: props.justify === 'center' ? 360 : '90%' }}
                      id="firstName"
                      variant="outlined"
                      className={classes.textField}
                      onChange={e => setFirstName(e.target.value)}
                      defaultValue={
                        formReducer && formReducer.firstName && formReducer.firstName.data && formReducer.firstName.data
                      }
                      autoComplete="new-password"
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('CODE_POSTAL', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('CODE_POSTAL', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('CODE_POSTAL', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('CODE_POSTAL', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <p className={classes.paragraphe}>Code postal de votre activité</p>
                    <Autocomplete
                      id="cp"
                      justify={props.justify}
                      type="cp"
                      step={4}
                      styleClasse="textfield"
                      defaultValue={formReducer && formReducer.cp && formReducer.cp.data}
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('VILLE', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('VILLE', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('VILLE', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('VILLE', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <p className={classes.paragraphe}>Ville</p>
                    <Autocomplete
                      justify={props.justify}
                      id="city"
                      type="city"
                      step={4}
                      styleClasse="select"
                      defaultValue={formReducer && formReducer.city && formReducer.city.data}
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('MAIL', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('MAIL', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('MAIL', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('MAIL', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <p className={classes.paragraphe}>Adresse mail</p>
                    <TextField
                      style={{ width: props.justify === 'center' ? 360 : '90%' }}
                      id="mail"
                      variant="outlined"
                      className={classes.textField}
                      onChange={e => setMail(e.target.value)}
                      error={!checkEmail}
                      helperText={!checkEmail && 'Email invalide'}
                      defaultValue={formReducer && formReducer.mail && formReducer.mail.data && formReducer.mail.data}
                      autoComplete="new-password"
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('TELEPHONE', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('TELEPHONE', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('TELEPHONE', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('TELEPHONE', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8}>
                    <p className={classes.paragraphe}>Téléphone</p>
                    <TextField
                      onChange={e => setPhone(e.target.value)}
                      error={!checkPhone}
                      helperText={!checkPhone && 'Téléphone invalide'}
                      id="phone"
                      variant="outlined"
                      className={classes.textField}
                      defaultValue={
                        formReducer && formReducer.phone && formReducer.phone.data && formReducer.phone.data
                      }
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('OPTIN_DONNEES', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('OPTIN_DONNEES', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('OPTIN_DONNEES', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('OPTIN_DONNEES', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    md={8}
                    style={{ marginTop: 34, marginLeft: props.justify === 'flex-start' && 16 }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event => setCheckboxData(event.target.checked)}
                          defaultValue={formReducer && formReducer.checkboxData && formReducer.checkboxData.data}
                          defaultChecked
                          id="checkboxData"
                          name="data"
                          color="primary"
                        />
                      }
                      label={
                        <span className={classes.checkbox}>
                          J&apos;accepte que mes données soient exploitées dans le cadre de ma demande de devis et de la
                          relation commerciale qui peut en découler. (obligatoire pour réalisation devis)
                        </span>
                      }
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            <MuiThemeProvider theme={tooltip}>
              {
                <Tooltip
                  disableHoverListener={
                    handleCodificationsInfobulles('OPTIN_ACTUALITES', codifications, codificationsTitre) === false
                  }
                  disableFocusListener={
                    handleCodificationsInfobulles('OPTIN_ACTUALITES', codifications, codificationsTitre) === false
                  }
                  interactive
                  placement="left"
                  title={
                    handleCodificationsInfobulles('OPTIN_ACTUALITES', codifications, codificationsTitre)
                      ? handleCodificationsInfobulles('OPTIN_ACTUALITES', codifications, codificationsTitre)
                      : ''
                  }
                >
                  <Grid item xs={12} sm={10} md={8} style={{ marginLeft: props.justify === 'flex-start' && 16 }}>
                    <FormControlLabel
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          onChange={event => setActuality(event.target.checked)}
                          defaultValue={actuality}
                          name="actuality"
                          color="primary"
                        />
                      }
                      label={
                        <span className={classes.checkbox}>
                          J&apos;accepte de recevoir la newsletter mensuelle d&apos;Entrepreneurs de la Cité.
                        </span>
                      }
                    />
                  </Grid>
                </Tooltip>
              }
            </MuiThemeProvider>

            {error && !gestionError(formReducer, props.step, selectedDate, name, firstName, phone) && (
              <Grid item xs={12} sm={10} md={8}>
                <p className={classes.error}>Veuillez remplir tout les champs.</p>
              </Grid>
            )}

            <Grid
              container
              item
              xs={12}
              sm={11}
              md={8}
              justify={props.justify !== 'flex-start' ? 'flex-start' : 'center'}
            >
              <Button
                component={Link}
                to="/tarification"
                variant="contained"
                className={props.buttonPrevMobile ? props.buttonPrevMobile : props.buttonPrevDesktop}
              >
                Précédent
              </Button>
              <Button
                onClick={onSubmit}
                variant="contained"
                className={props.buttonNextMobile ? props.buttonNextMobile : props.buttonNextDesktop}
              >
                Finaliser et recevoir mon devis
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

Form.propTypes = {
  step: PropTypes.number,
  justify: PropTypes.string,
  buttonNextMobile: PropTypes.string,
  buttonPrevMobile: PropTypes.string,
  buttonNextDesktop: PropTypes.string,
  buttonPrevDesktop: PropTypes.string
};
