import React from 'react';
export const gestionError = (data, step, date) => {
  if (step === 1) {
    if (data.departement && data.departement.data && data.departement.data.length === 2) {
      if (data.reseau && data.reseau.data !== '') {
        if (date !== null || date !== '') {
          return true;
        }
      }
    }
    return false;
  }

  if (step === 2) {
    if (
      (data.activite && data.activite.data !== null) ||
      (data.activite && data.activite.data && data.activite.data !== '')
    ) {
      if ((data.local && data.local.data !== null) || (data.local && data.local.data && data.local.data !== '')) {
        if (
          (data.turnover && data.turnover.data !== null) ||
          (data.turnover && data.turnover.data && data.turnover.data !== '')
        ) {
          if (
            (data.valueStock && data.valueStock.data !== null) ||
            (data.valueStock && data.valueStock.data && data.valueStock.data !== '')
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  if (step === 4) {
    if ((data.name && data.name.data !== null) || (data.name && data.name.data !== '')) {
      if ((data.firstName && data.firstName.data !== null) || (data.firstName && data.firstName.data !== '')) {
        if (data.phone && data.phone.data.length === 10) {
          if (data.cp && data.cp.data && data.cp.data.value && data.cp.data.value.length === 5) {
            if ((data.city && data.city.data !== null) || (data.city && data.city.data && data.city.data !== '')) {
              if (data.checkboxData && data.checkboxData.data) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }
};

export const getValueStock = value => {
  switch (value) {
    case 'OPTION_1:VALEUR_STOCK':
      return 'Entre 0 et 5000 €';
    case 'OPTION_2:VALEUR_STOCK':
      return 'Entre 5000 et 10 000 €';
    case 'OPTION_3:VALEUR_STOCK':
      return 'Entre 10 000 et 20 000 €';
    default:
      return '';
  }
};

export const handleCodificationsInfobulles = (value, codif, codifTitre) => {
  if (
    codif &&
    codif.data &&
    Array.isArray(codif.data.body) &&
    codif.data.body.length > 0 &&
    codifTitre &&
    codifTitre.data &&
    Array.isArray(codifTitre.data.body) &&
    codifTitre.data.body.length > 0
  ) {
    const findCodif =
      codif && codif.data && Array.isArray(codif.data.body) && codif.data.body.find(data => data.code === value);

    const findCodifTitre =
      codifTitre &&
      codifTitre.data &&
      Array.isArray(codifTitre.data.body) &&
      codifTitre.data.body.find(data => data.code === value);

    if (typeof findCodif !== 'undefined' && typeof findCodifTitre !== 'undefined') {
      if (findCodif.libelle !== '' || findCodifTitre.libelle !== '') {
        return (
          <div>
            <span
              style={{
                fontFamily: 'Work Sans',
                fontSize: 14,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.5,
                letterSpacing: 'normal',
                fontWeight: 600,
                color: '#e85211',
                display: 'flex',
                marginBottom: 20,
                marginTop: 10
              }}
              dangerouslySetInnerHTML={{
                __html: findCodifTitre && findCodifTitre.libelle
              }}
            ></span>
            <span
              style={{
                fontFamily: 'Work Sans',
                fontSize: 14,
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.5,
                letterSpacing: 'normal',
                color: '#4a4a49',
                margin: 10
              }}
              dangerouslySetInnerHTML={{
                __html: findCodif && findCodif.libelle
              }}
            ></span>
          </div>
        );
      }
    } else {
      return false;
    }
  }
};
