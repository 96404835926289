import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import { makeStyles } from "@material-ui/core/styles";
import img from "assets/img/bi-gout.png";
import Form from "components/Form/Form";
import Panel from "components/Panel/Panel";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles({
  grid: {
    height: "100vh"
  },
  img: {
    marginBottom: 16,
    marginTop: 16,
    marginLeft: 16
  },
  buttonPrevDesktop: {
    marginTop: 35,
    marginBottom: 20,
    height: 42,
    borderRadius: 8,
    backgroundColor: "#ffff",
    border: "solid 2px #E85313",
    color: "#E85313",
    fontFamily: "Work Sans",
    fontSize: 14,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center"
  },
  buttonNextDesktop: {
    width: 132,
    fontFamily: "Work Sans",
    fontSize: 14,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    marginTop: 35,
    marginBottom: 20,
    marginLeft: 26,
    height: 42,
    borderRadius: 8,
    backgroundColor: "#E85313",
    color: "#ffff"
  },
  buttonPrevMobile: {
    marginBottom: 32,
    width: "45%",
    marginTop: 42,
    height: 42,
    marginRight: 5,
    borderRadius: 8,
    backgroundColor: "#ffff",
    border: "solid 2px #E85313",
    color: "#E85313"
  },
  buttonNextMobile: {
    marginBottom: 32,
    marginTop: 42,
    width: "45%",
    marginLeft: 5,
    height: 42,
    borderRadius: 8,
    backgroundColor: "#E85313",
    color: "#ffff"
  }
});

export default function Step2(props) {
  const classes = useStyles();

  return (
    <Grid className={classes.grid} container justify="flex-end">
      <Hidden only={["md", "sm", "lg", "xl"]}>
        <Grid item xs={12} className={classes.img}>
          <img src={img} alt="group" />
        </Grid>
      </Hidden>

      <Grid item xs={12} sm={4} lg={4} xl={3}>
        <Panel step={2} history={props.history} />
      </Grid>

      <Hidden only={"xs"}>
        <Grid item xs={12} sm={8} lg={8} xl={9}>
          <Form
            step={2}
            justify="center"
            buttonPrevDesktop={classes.buttonPrevDesktop}
            buttonNextDesktop={classes.buttonNextDesktop}
          />
        </Grid>
      </Hidden>

      <Hidden only={["md", "sm", "lg", "xl"]}>
        <Grid item xs={12} sm={8} lg={9}>
          <Form
            step={2}
            justify="flex-start"
            buttonPrevMobile={classes.buttonPrevMobile}
            buttonNextMobile={classes.buttonNextMobile}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}

Step2.propTypes = {
  history: PropTypes.object
};
