import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import { makeStyles } from "@material-ui/core/styles";
import img from "assets/img/bi-gout.png";
import Form from "components/Form/Form";
import Panel from "components/Panel/Panel";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import appActions from "redux/actions/appActions";

const useStyles = makeStyles({
  grid: {
    height: "100vh"
  },
  img: {
    marginBottom: 16,
    marginTop: 16,
    marginLeft: 16
  },
  buttonNextDesktop: {
    width: 132,
    fontFamily: "Work Sans",
    fontSize: 14,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    marginTop: 40,
    height: 42,
    borderRadius: 8,
    backgroundColor: "#E85313",
    color: "#ffff",
    marginBottom: 25
  },
  buttonNextMobile: {
    marginTop: 40,
    width: "90%",
    height: 42,
    borderRadius: 8,
    backgroundColor: "#E85313",
    color: "#ffff",
    marginBottom: 15
  }
});

export default function Step1(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.get_codifications_infobulles());
  }, [dispatch]);

  useEffect(() => {
    dispatch(appActions.get_codifications_infobulles_titre());
  }, [dispatch]);

  useEffect(() => {
    dispatch(appActions.get_codifications_menu());
  }, [dispatch]);

  dispatch(appActions.data_form("jwt", "jwt"));

  return (
    <div>
      <Grid className={classes.grid} container justify="flex-end">
        <Hidden only={["md", "sm", "lg", "xl"]}>
          <Grid item xs={12} className={classes.img}>
            <img src={img} alt="group" />
          </Grid>
        </Hidden>

        <Grid item xs={12} sm={4} lg={4} xl={3}>
          <Panel step={1} history={props.history} />
        </Grid>

        <Hidden only={"xs"}>
          <Grid item xs={12} sm={8} lg={8} xl={9}>
            <Form
              step={1}
              justify="center"
              buttonNextDesktop={classes.buttonNextDesktop}
            />
          </Grid>
        </Hidden>

        <Hidden only={["md", "sm", "lg", "xl"]}>
          <Grid item xs={12} sm={8} lg={9}>
            <Form
              step={1}
              justify="flex-start"
              buttonNextMobile={classes.buttonNextMobile}
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}

Step1.propTypes = {
  history: PropTypes.object
};
